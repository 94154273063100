"use client";

import { useEffect, useState } from "react";

import "./globals.css";

import "@fontsource/palanquin/300.css";
import "@fontsource/palanquin/400.css";
import "@fontsource/palanquin/500.css";
import "@fontsource/palanquin/600.css";
import "@fontsource/palanquin/700.css";
import "@fontsource/palanquin-dark/400.css";
import "@fontsource/palanquin-dark/500.css";
import "@fontsource/palanquin-dark/600.css";
import "@fontsource/palanquin-dark/700.css";

type PortalLayoutProps = {
  children: React.ReactNode;
};

type Theme = "light" | "dark";

export default function PortalLayout({ children }: PortalLayoutProps) {
  const [theme, setTheme] = useState<Theme>("dark");

  useEffect(() => {
    const systemTheme = window.matchMedia("(prefers-color-scheme: dark)")
      .matches
      ? "dark"
      : "light";
    setTheme(systemTheme);

    const listener = (e: MediaQueryListEvent) => {
      setTheme(e.matches ? "dark" : "light");
    };

    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    mediaQuery.addEventListener("change", listener);

    return () => {
      mediaQuery.removeEventListener("change", listener);
    };
  }, []);

  return (
    <html lang="en" className={`${theme === "dark" && "dark"} h-full`}>
      {/* @TODO: Add in header that'll control the theme */}
      <body className="h-full">{children}</body>
    </html>
  );
}
